<template>
  <h1>Not Found!</h1>
  <p>
    <a href="/">Go home?</a>
  </p>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<style scoped>
p {
  margin: 15vw;
  text-align: center;
}

h1 {
  margin: 15vw;
  color: var(--border);
  font-size: 2em;
  text-align: center;
}
</style>