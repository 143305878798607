<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {
    //
  },
};
</script>

<style>
#app h1,
h3 {
  text-align: center;
}

#modals {
  min-height: 100vh;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
