<template>
  <div id="nav">
    <nav-bar></nav-bar>
  </div>
  <div class="bg-primary w-100">
    <main class="main-content border-radius-lg">
      <h1>Overzicht facturen</h1>

      <div class="align-items-center">
        <div class="searchbar">
          <input
            type="text"
            class="form-control form-control-lg text_input"
            v-model="number"
            placeholder="Voer een nummer in (bv. FAC-114733)"
            @keyup.enter="fetchInvoiceByNumber(number)"
          /><button
            class="button"
            :class="{ 'button--loading': loading }"
            v-on:click="fetchInvoiceByNumber(number)"
          >
            <img src="@/assets/icons-search.svg" />
          </button>
        </div>

        <button
          class="btn btn-lg btn-secondary btn-lg w-100 mt-4 mb-0"
          :class="{ 'button--loading': loading }"
          v-on:click="fetchInvoices(size)"
        >
          Bekijk de laatste (2500) verwerkte facturen
        </button>
        <button
          class="btn btn-lg btn-secondary btn-lg w-100 mt-4 mb-0"
          :class="{ 'button--loading': loading }"
          v-on:click="fetchUnprocessedInvoices()"
        >
          Bekijk alle onverwerkte facturen
        </button>
        <!-- <div>
          <select class="dropdown" v-model="processed">
            <option value="1">Verwerkt</option>
            <option value="0">Niet verwerkt</option>
          </select>
        </div> -->

        <section class="grid" v-if="invoiceByNumber == null && !loading">
          <div class="container">
            <table class="invoicestable table table-striped">
              <thead>
                <tr>
                  <th>Nummer</th>
                  <th>Datum</th>
                  <th>ID</th>
                  <th>Bericht</th>
                  <th>CCV ID</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="invoice in paginateInvoices"
                  :key="invoice.ccvId"
                  active
                  v-on:click="showModal()" 
                >
                  <th scope="row">{{ invoice.invoiceNumber }}</th>
                  <td>{{ formatDate(invoice.created_at) }}</td>
                  <td>{{ invoice.id }}</td>
                  <td>{{ invoice.message }}</td>
                  <td>{{ invoice.ccvId }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section class="grid" v-if="invoiceByNumber != null && !loading">
          <div
            class="card justify-content-xl-center"
            active
            v-on:click="showModal()"
          >
            <div class="card-body p-3">
              <div class="row item">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">
                      Nummer: {{ invoiceByNumber.invoiceNumber }}
                    </p>
                    <p class="text-sm mb-0">ID: {{ invoiceByNumber.id }}</p>
                    <p class="text-sm mb-0">
                      Datum: {{ formatDate(invoiceByNumber.created_at) }}
                    </p>
                    <p v-if="invoiceByNumber.message" class="text-sm mb-0">
                      Bericht: {{ invoiceByNumber.message }}
                    </p>
                    <p class="text-sm mb-0">
                      CCV ID: {{ invoiceByNumber.ccvId }}
                    </p>
                    <!-- <button
                    v-if="invoice.message"
                    class="
                      btn btn-lg btn-secondary btn-lg
                      w-100
                      mt-4
                      mb-0
                      process-button
                    "
                    v-on:click="reprocess(invoice.id)"
                  >
                    Opnieuw verwerken
                  </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="grid" v-if="loading">
          <img src="@/assets/loading.svg" />
        </section>

        <section v-if="invoiceByNumber == null">
          <div v-if="total" class="container-fluid">
            <div class="row">
              <div class="col">
                <pagination v-if="total > 3" :total="total"></pagination>
              </div>
            </div>
          </div>
          <div class="spacer" />
          <div class="spacer" />
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "../components/NavBar.vue";
import Pagination from "../components/Pagination.vue";
import axios from "axios";

export default {
  name: "Invoices",
  components: {
    //
    NavBar,
    Pagination,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      size: 2500,
      number: null,
      processed: 1,
      isShow: false,
      loading: false,
      total: 0,
      invoiceByNumber: null,
      processedView: true,
    };
  },

  mounted() {
    this.loading = true;

    this.getInvoices().then(() => {
      this.total = this.invoices.length;
      this.loading = false;
    });
  },

  methods: {
    ...mapActions({
      getInvoices: "invoice/getInvoices",
      reprocessInvoice: "invoice/reprocessInvoice",
      getUnprocessedInvoices: "invoice/getUnprocessedInvoices",
    }),

    async fetchInvoices(size) {
      this.loading = true;

      this.getInvoices(size)
        .then(() => {
          this.loading = false;
          this.invoiceByNumber = null;
          this.processedView = true;
          this.total = this.invoices.length;
          this.$router.push({ name: "Invoices", query: { page: "1" } });
        })
        .catch(() => (this.loading = false));
    },
    async reprocess(id) {
      this.reprocessInvoice(id);
    },
    async fetchUnprocessedInvoices() {
      this.loading = true;

      this.getUnprocessedInvoices()
        .then(() => {
          this.loading = false;
          this.invoiceByNumber = null;
          this.processedView = false;
          this.total = this.unprocessedInvoices.length;
          this.$router.push({ name: "Invoices", query: { page: "1" } });
        })
        .catch(() => (this.loading = false));
    },
    async fetchInvoiceByNumber(number) {
      this.loading = true;

      return axios.get(`/api/invoices/search/${number}`).then((response) => {
        this.loading = false;
        this.invoiceByNumber = response.data.data;
      });
    },

    checkStatus(invoiceStatus) {
      return invoiceStatus == 0 ? "Niet verwerkt" : "Verwerkt";
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("default", {
        dateStyle: "long",
        timeStyle: "long",
      }).format(date);
    },
    showModal() {
      this.isShow = true;
    },
    closeModal() {
      this.isShow = false;
    },
  },

  // watch: {
  //   $route() {
  //     this.total = this.invoices.filter(
  //       (invoice) => invoice.processed == this.processed
  //     ).length;
  //   },
  //   processed() {
  //     this.total = this.invoices.filter(
  //       (invoice) => invoice.processed == this.processed
  //     ).length;
  //   },
  // },

  computed: {
    ...mapGetters({
      invoices: "invoice/invoices",
      unprocessedInvoices: "invoice/unprocessedInvoices",
    }),
    filterInvoicesByStatus: function () {
      return this.invoices.filter(
        (invoice) => invoice.processed == this.processed
      );
    },
    paginateInvoices() {
      const currentPage = isNaN(parseInt(this.$route.query.page))
        ? 1
        : parseInt(this.$route.query.page);
      const perPage = 12;
      let allInvoices = [];

      allInvoices = this.processedView
        ? this.invoices
        : this.unprocessedInvoices;

      if (this.processedView) {
        allInvoices = allInvoices.filter(
          (invoice) => invoice.processed == true
        );
      }

      const totalArticles = allInvoices.length;
      const lastPage = Math.ceil(totalArticles / perPage);
      const lastPageCount =
        totalArticles % perPage === 0
          ? totalArticles / perPage
          : totalArticles % perPage;

      const skipNumber = () => {
        if (currentPage === 1) {
          return 0;
        }
        if (currentPage === lastPage) {
          return totalArticles - lastPageCount;
        }
        return (currentPage - 1) * perPage;
      };

      const paginatedArticles = allInvoices.slice(
        skipNumber(),
        skipNumber() + perPage
      );

      return paginatedArticles;
    },
  },
};
</script>

<style scoped>
input:focus::placeholder {
  color: transparent;
}
div.card {
  max-width: 750px;
}
div.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  padding-bottom: 15px;
}
.bg-primary {
  display: grid;
  justify-content: center;
}
div.align-items-center > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.align-items-center > section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.process-button {
  max-width: 300px;
}
.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;
}
.item {
  font-size: 30px;
}
.container-fluid {
  padding-top: 25px;
}
.dropdown {
  margin: 25px;
}
div.container {
  width: 98%;
  margin: 1%;
  background-color: white;
}
table#invoicestable {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}
tr,
td {
  text-align: left;
}
div.searchbar {
  display: flex;
  margin: 5px;
  padding: 5px;
}
select,
input,
button {
  border-width: 0px;
}
.button {
  height: 48px;
  width: 48px;
}
.form-control {
  border-radius: 0%;
}
.grid {
  margin-top: 25px;
}
td {
white-space: pre-line;
}
</style>
