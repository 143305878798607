import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import BootstrapVue3 from 'bootstrap-vue-3'

// Optional, since every component import their Bootstrap funcionality
// the following line is not necessary
// import 'bootstrap'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import 'vue-universal-modal/dist/index.css'

import VueUniversalModal from 'vue-universal-modal'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASEURL

store.dispatch('auth/me').then(() => {
  createApp(App).use(BootstrapVue3).use(store).use(router).use(VueUniversalModal, {
    teleportTarget: '#modals'
  }).mount('#app')
})
