<template>
  <div id="nav"><nav-bar></nav-bar></div>
  <div class="bg-primary position-absolute w-100">
    <main class="main-content position-relative border-radius-lg">
      <nav-bar-top></nav-bar-top>
      <div class="container-fluid py-4 home">
        <h1 class="mt-3 mb-0">SPELHUIS ADMIN</h1>
        <h3>Welkom, {{ user.name }}</h3>
        <br/>
        <img src="@/assets/invoice-bill.svg"/>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "../components/NavBar.vue";
import NavBarTop from "../components/NavBarTop.vue";

export default {
  name: "Dashboard",

  components: {
    //
    NavBar,
    NavBarTop,
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

    async signOut() {
      await this.signOutAction();

      this.$router.push({ name: "Home" });
    },
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>

<style>
div.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>