import axios from "axios";

export default {
  namespaced: true,

  state: {
    invoices: [],
    unprocessedInvoices: [],
  },

  getters: {
    invoices(state) {
      return state.invoices;
    },
    unprocessedInvoices(state) {
      return state.unprocessedInvoices;
    },
  },

  mutations: {
    SET_INVOICES(state, value) {
      state.invoices = value;
    },
    SET_UNPROCESSED_INVOICES(state, value) {
      state.unprocessedInvoices = value;
    },
  },

  actions: {
    async getInvoices({ commit }, size) {
      if (size === undefined || size === null) {
        size = 2500;
      }
      return axios
        .get(`/api/invoices/${size}`)
        .then((response) => {
          commit("SET_INVOICES", response.data.data);
        })
        .catch(() => {
          commit("SET_INVOICES", null);
        });
    },
    async reprocessInvoice(_, id) {
      return axios.post(`/api/invoices/reprocessInvoice/${id}`).then(() => {
        const item = this.state.invoices.find((item) => item.id === id);
        Object.assign(item.processed, true);
      });
    },
    async getUnprocessedInvoices({ commit }) {
      return axios
        .get(`/api/invoices/unprocessed`)
        .then((response) => {
          commit("SET_UNPROCESSED_INVOICES", response.data.data);
        })
        .catch(() => {
          commit("SET_UNPROCESSED_INVOICES", null);
        });
    },
  },
};
