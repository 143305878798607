<template>
    <ol class="pagination">
      <li class="pagination__item pagination__item--first">
        <span v-if="currentPage === 1" class="icon-link--muted"
          ><i class="las la-angle-double-left"></i> Eerste pagina</span
        >
        <router-link v-else :to="{ path: 'invoices', query: { page: 1 } }">
          <i class="las la-angle-double-left"></i> Eerste pagina
        </router-link>
      </li>
      <li class="pagination__item">
        <span v-if="currentPage === 1" class="icon-link--muted"
          ><i class="las la-angle-left"></i> Vorige pagina</span
        >
        <router-link
          v-else
          :to="{ path: 'invoices', query: { page: prevPage } }"
        >
          <i class="las la-angle-left"></i> Vorige pagina
        </router-link>
      </li>
      <li class="pagination__item">
        <span v-if="currentPage === totalPages" class="icon-link--muted"
          >Volgende pagina <i class="las la-angle-right"></i
        ></span>
        <router-link
          v-else
          :to="{ path: 'invoices', query: { page: nextPage } }"
        >
          Volgende pagina <i class="las la-angle-right"></i>
        </router-link>
      </li>
      <li class="pagination__item pagination__item--last">
        <span v-if="currentPage === totalPages" class="icon-link--muted">
          Laatste pagina <i class="las la-angle-double-right"></i
        ></span>
        <router-link
          v-else
          :to="{ path: 'invoices', query: { page: totalPages } }"
        >
          Laatste pagina <i class="las la-angle-double-right"></i>
        </router-link>
      </li>
    </ol>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {};
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
    prevPage() {
      return this.currentPage > 1 ? this.currentPage - 1 : 1;
    },
    nextPage() {
      return this.currentPage < this.totalPages
        ? this.currentPage + 1
        : this.totalPages;
    },
  },
};
</script>

<style scoped>
.pagination__item {
  background-color: whitesmoke;
  padding: 10px 10px 10px 10px;
  margin-right: 5px;
  border-radius: 15px;
}
a:link {
  text-decoration: none;
}
ol{
  display:flex;
  justify-content: center;

}
ol li{
  display: list-item;
  padding: 5px 10px;
  margin: 0 3px;
}
</style>