<template>
  <template v-if="!authenticated">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="
                col-xl-4 col-lg-5 col-md-7
                d-flex
                flex-column
                mx-lg-0 mx-auto
              "
            >
              <div>
                  <img src="@/assets/spelhuis_home.svg"/>
              </div>
              
              <div class="card card-plain">
                
                <div class="card-header pb-0 text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                </div>
                <div class="card-body">
                  <form action="#" @submit.prevent="submit">
                    <p v-if="errors.length">
                      <ul>
                        <div v-for="error in errors" :key="error" class="form-error"><span class="text-danger text-left">{{ error }}</span></div>
                      </ul>
                    </p>

                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="email"
                        placeholder="email"
                        id="email"
                        v-model="form.email"
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        name="password"
                        placeholder="password"
                        id="password"
                        autocomplete="on"
                        v-model="form.password"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0 "
                        :class="{ 'button--loading' : loading }"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="
                col-6
                d-lg-flex d-none
                h-100
                my-auto
                pe-0
                position-absolute
                top-0
                end-0
                text-center
                justify-content-center
                flex-column
              "
            >
              <span class="mask bg-gradient-primary "><img class="spelhuis_logo" src="@/assets/over-ons-beelden-b.png"/></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errors: [],
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),

    async submit() {
      this.loading = true;
      this.errors = [];

      if (this.form.email && this.form.password) {
        this.signIn(this.form)
          .then(() => {
            this.loading = false;
            this.$router.replace({ name: "Dashboard" });
          })
          .catch((error) => {
            if (error.message) {
              this.errors.push("These credentials do not match our records.");
            }
            this.loading = false;
          });
      }

      if (!this.form.email) {
        this.loading = false;
        this.errors.push("email required.");
      }
      if (!this.form.password) {
        this.loading = false;
        this.errors.push("password required.");
      }
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
};
</script>

<style scoped>
.spelhuis_logo {
  margin-top: 125px;
  width: 715px;
  height: 715px;
}
</style>
