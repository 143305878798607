<template>
  <aside
    class="
      sidenav
      bg-white
      navbar navbar-vertical navbar-expand-xs
      border-0 border-radius-xl
      my-3
      fixed-start
      ms-4
    "
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="
          fas
          fa-times
          p-3
          cursor-pointer
          text-secondary
          opacity-5
          position-absolute
          end-0
          top-0
          d-none d-xl-none
        "
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="navbar-brand m-0 text-center" href="/">
        <img
          src="@/assets/spelhuis_main.jpg"
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
      </a>
    </div>
    <hr class="horizontal dark mt-0" />
    <div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link-text ms-1"
            ><i class="fa fa-table-columns text-sm mx-3 opacity-10"></i
            >Dashboard</router-link
          >
        </li>
        <li class="nav-item">
          <router-link :to="{ path: 'invoices', query: { page : 1 }}" class="nav-link-text ms-1"
            ><i class="fa fa-table-columns text-sm mx-3 opacity-10"></i
            >Invoices</router-link
          >
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>