<template>
  <nav
    class="
      navbar navbar-main navbar-expand-lg
      px-0
      mx-4
      shadow-none
      border-radius-xl
    "
    id="navbarBlur"
    data-scroll="false"
  >
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb"></nav>
      <ul class="navbar-nav justify-content-end">
        <li class="nav-item d-flex align-items-center">
          <a
            href="#"
            @click.prevent="signOut"
            class="nav-link text-white font-weight-bold px-0"
          >
            <i class="fa fa-user me-sm-1"></i>
            <span class="d-sm-inline d-none">Sign out</span>
          </a>
        </li>
        <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
          <a
            href="javascript:;"
            class="nav-link text-white p-0"
            id="iconNavbarSidenav"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line bg-white"></i>
              <i class="sidenav-toggler-line bg-white"></i>
              <i class="sidenav-toggler-line bg-white"></i>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NavBarTop",

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

    async signOut() {
      await this.signOutAction();

      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>